
import CreateUploadDialog from "@/components/ingest/uploadDialogs/CreateUploadDialog"
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    CreateUploadDialog,
  },
  props: {
    blok: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    ingestUploadTypes: INGEST_UPLOADS_TYPES,
    droneUploadObject: undefined,
    droneUploadDialog: false,
  }),
  computed: {
    ...mapStores(useIngestFileUploaderStore, useProjectStore, useAccountStore),
  },
  methods: {
    async generateDroneUploadLink() {
      if (!this.projectStore.selectedProjectExid) {
        return
      }
      try {
        const response = await IngestApi.drone.createUpload(
          this.projectStore.selectedProjectExid,
          {
            uploadedBy: this.accountStore.email,
          }
        )

        this.droneUploadObject = response
        const uploadStats = {
          percentage: 0,
          totalSize: 0,
          uploadedSize: 0,
          isUploading: false,
          isProcessing: false,
          uploadFinished: false,
          uploadType: INGEST_UPLOADS_TYPES.droneUploads,
        }
        this.ingestFileUploaderStore.currentUploadStats = uploadStats
        this.ingestFileUploaderStore.uploadStats.push(uploadStats)
        this.droneUploadDialog = true
      } catch (error) {
        this.$notifications.error({
          text: "Could not get token ",
          error,
        })
      }
    },
    onDroneUplaodDialogClosed() {
      this.ingestFileUploaderStore.currentBIMUploadStat = undefined
      this.droneUploadDialog = false
      this.droneUploadObject = undefined
    },
  },
}
